exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-enviado-js": () => import("./../../../src/pages/enviado.js" /* webpackChunkName: "component---src-pages-enviado-js" */),
  "component---src-pages-equipo-js": () => import("./../../../src/pages/equipo.js" /* webpackChunkName: "component---src-pages-equipo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pagina-js": () => import("./../../../src/pages/pagina.js" /* webpackChunkName: "component---src-pages-pagina-js" */),
  "component---src-pages-proyectos-js": () => import("./../../../src/pages/proyectos.js" /* webpackChunkName: "component---src-pages-proyectos-js" */),
  "component---src-pages-servicio-js": () => import("./../../../src/pages/servicio.js" /* webpackChunkName: "component---src-pages-servicio-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */)
}

